import React from "react"
import PropTypes from "prop-types"
import PhoneInput from "react-phone-number-input"

export const Input = ({
  label,
  name,
  type,
  value,
  placeholder,
  error,
  onChange,
  country,
  newsletter,
  rows = 3,
}) => {
  const classNames = `${
    error
      ? `border-red-300 placeholder-red-300 focus:ring-red-500 focus:border-red-600`
      : `border-gray-200 focus:ring-black focus:border-black`
  } ${newsletter && `px-5 py-3`} w-full rounded-md shadow-sm border`

  return (
    <>
      {label && (
        <label htmlFor={name} className="block mb-2 text-sm font-semibold">
          {label}
        </label>
      )}
      <div className="relative w-full">
        {type === "textarea" && (
          <textarea
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            className={classNames}
            rows={rows}
          />
        )}
        {type === "tel" && (
          <PhoneInput
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            className={`${error && `error`}`}
            defaultCountry={country}
            international
            flagUrl="https://cdn.jsdelivr.net/gh/hampusborgos/country-flags@main/svg/{xx}.svg"
            //flagUrl="https://flag.pk/flags/4x3/{xx}.svg" this site is down
          />
        )}
        {type !== "textarea" && type !== "tel" && (
          <input
            type={type}
            value={value || ""}
            placeholder={placeholder}
            onChange={onChange}
            className={classNames}
          />
        )}
        {error && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
            <svg
              className="w-5 h-5 text-red-500"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        )}
      </div>
      {error && !newsletter && (
        <p className="block mt-1 text-sm text-red-600">{error}</p>
      )}
    </>
  )
}

Input.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.bool,
  ]),
  onChange: PropTypes.func.isRequired,
}
