import React, { useState, useEffect, useContext } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { useWindowWidth } from "@react-hook/window-size"
import { Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/scss"

import { useStore, Locale } from "../../context"
import { Price } from "../../components/utils"

import Surface from "../../images/property/surface.inline.svg"
import Land from "../../images/property/land.inline.svg"
import Bedroom from "../../images/property/bedroom.inline.svg"
import Shower from "../../images/property/shower.inline.svg"
import Home from "../../images/property/home.inline.svg"
import Heart from "../../images/property/heart.inline.svg"

export const PropertyCard = ({ property, large, sliderDisabled, listView }) => {
  let { locale } = useContext(Locale)

  const width = useWindowWidth()
  const { state, dispatch } = useStore()
  const [saved, setSaved] = useState(false)

  // Check if saved property
  useEffect(() => {
    const saved =
      state.properties &&
      state.properties.some(x => x.reference === property.reference)

    setSaved(saved)
  }, [state, property.reference])

  const title = property._allTitleLocales.find(i => i.locale === locale).value
  const slug = property._allSlugLocales.find(i => i.locale === locale).value
  const propertyType = property.propertyType._allNameLocales.find(i => i.locale === locale).value
  const city = property.city._allNameLocales.find(i => i.locale === locale).value
  const country = property.city.country._allNameLocales.find(i => i.locale === locale).value
  const unavailable = property.propertyStatus.originalId === "34805497" || property.propertyStatus.originalId === "34805527"

  let height = 224
  if (large && width > 1024) height = 360

  return (
    <>
      <div className={`relative h-full bg-white cursor-pointer property-card ${listView && `xl:flex`}`}>
        <div className={`${listView && `w-full xl:w-2/5 xl:flex-shrink-0`}`}>
          {sliderDisabled && property.gallery && property.gallery[0] ? (
            <Link to={slug && "/" + locale + "/" + slug}>
              <Img
                fluid={property.gallery[0].fluid}
                alt={
                  property.gallery[0].smartTags &&
                  property.gallery[0].smartTags.join(" ")
                }
                style={{
                  height: listView ? "100%" : height,
                }}
                className="w-full"
              />
            </Link>
          ) : (
            <Swiper
              modules={[Pagination]}
              slidesPerView={1}
              pagination={{
                clickable: true,
                dynamicBullets: true,
              }}
              watchOverflow
              lazy
              className={`${listView && `h-[224px] xl:h-full`}`}
            >
              {property.gallery && property.gallery.map((item, key) => (
                <SwiperSlide key={key}>
                  <Link to={slug && "/" + locale + "/" + slug}>
                    <Img
                      fluid={item.fluid}
                      alt={item.smartTags && item.smartTags.join(" ")}
                      style={{
                        height: listView ? "100%" : height,
                      }}
                      className="w-full"
                    />
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </div>
        <div className={`p-6 ${listView && `xl:p-8 xl:flex xl:flex-col xl:items-stretch`}`}>
          <div className="flex flex-wrap mb-2">
            {propertyType && (
              <div className="flex items-center mb-2 mr-6 text-gray-500">
                <Home className="w-5 h-5 mr-2" />
                <p className="text-sm">{propertyType}</p>
              </div>
            )}
            {property.surface > 0 ? (
              <div className="flex items-center mb-2 mr-6 text-gray-500">
                <Surface className="w-5 h-5 mr-2" />
                <p className="text-sm">{property.surface} m²</p>
              </div>
            ) : (
              property.land > 0 && (
                <div className="flex items-center mb-2 mr-6 text-gray-500">
                  <Land className="w-5 h-5 mr-2" />
                  <p className="text-sm">{property.land} m²</p>
                </div>
              )
            )}
            {property.bedrooms > 0 && (
              <div className="flex items-center mb-2 mr-6 text-gray-500">
                <Bedroom className="w-5 h-5 mr-2" />
                <p className="text-sm">{property.bedrooms}</p>
              </div>
            )}
            {property.bathrooms > 0 && (
              <div className="flex items-center mb-2 mr-6 text-gray-500">
                <Shower className="w-5 h-5 mr-2" />
                <p className="text-sm">{property.bathrooms}</p>
              </div>
            )}
          </div>
          <h3 className={`${listView ? `text-lg md:text-xl` : `text-lg`} font-semibold text-gray-900 font-headline`}>
            <Link
              to={slug && "/" + locale + "/" + slug}
              className={`${!slug && `pointer-events-none`} property-card-title static inline mr-2`}
            >
              {title}
            </Link>
            {!unavailable &&
              <Heart
                className={`${
                  saved ? `text-gold` : `text-gray-300`
                } relative z-10 inline w-6 h-6 cursor-pointer transition duration-200 top-[-1px]`}
                onClick={() => dispatch({ type: "toggle", property: property })}
              />
            }
          </h3>
          {listView &&
            <p className="hidden mt-1 mb-3 text-gray-500 xl:block text-md">
              {property.description.replace(/([.?!])\s*(?=[A-Z])/g, "$1 |").split("|").slice(0, 2)}
            </p>
          }
          <p className={`text-gray-500 text-md mt-1 ${listView && `xl:mt-auto`}`}>
            <Price
              price={property.price}
              hidePrice={property.hidePrice}
              currency={property.currency.code}
              status={property.propertyStatus}
            />{" "}
            · {city}, {country}
          </p>
        </div>
      </div>
    </>
  )
}
