import React, { useState, useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Transition } from "@headlessui/react"
import OutsideClickHandler from "react-outside-click-handler"
import { Helmet } from "react-helmet"

import { useStore, Locale } from "../../context"

import World from "../../images/property/world.inline.svg"

export const Currency = ({ transparent, small, isOpen }) => {
  let { locale } = useContext(Locale)

  const { state, dispatch } = useStore()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  // Get alternate languages from Helmet
  const helmet = Helmet.peek()
  const alternateLinks = helmet.linkTags && helmet.linkTags.filter(x => x.rel === "alternate");


  const header = useStaticQuery(graphql`
    query CurrencyQuery {
      en: datoCmsHeader(locale: { eq: "en" }) {
        language
        currency
      }
      fr: datoCmsHeader(locale: { eq: "fr" }) {
        language
        currency
      }
      languages_en: allDatoCmsLanguage(
        
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          ...Language
        }
      }
      languages_fr: allDatoCmsLanguage(
        
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          ...Language
        }
      }
      currencies_en: allDatoCmsCurrency(
        filter: { locale: { eq: "en" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          ...Currency
        }
      }
      currencies_fr: allDatoCmsCurrency(
        filter: { locale: { eq: "fr" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          ...Currency
        }
      }
    }
  `)

  
//   console.log('locale', locale);
// console.log('header["languages_" + locale].nodes', header["languages_" + locale].nodes);

  return (
    <OutsideClickHandler onOutsideClick={() => setIsDropdownOpen(false)}>
      <button
        className={`${
          transparent && !isOpen
            ? `text-gray-200 hover:text-white`
            : `text-gray-500 hover:text-blue`
        } ${
          small ? `ml-6` : `ml-6 lg:ml-8`
        } flex items-center transition duration-200 focus:outline-none`}
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        <World className="w-5 h-5" />
        <p className={`ml-4 ${small ? `hidden` : `hidden lg:inline`}`}>
          {header["languages_" + locale].nodes.find(i => i.code === locale)?.name}{" "}
          {state.currency && `(${state.currency})`}
        </p>
      </button>
      <Transition
        show={isDropdownOpen}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        className="absolute right-0 z-50 w-56 mt-2 origin-top-right rounded-md shadow-lg"
      >
        <div
          className="bg-white rounded-md shadow-xs"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          <div className="px-4 pt-3 pb-1">
            <p className="text-sm font-medium leading-5 text-gray-500 truncate">
              {header[locale].language}
            </p>
          </div>
          <div className="py-1">
            {header["languages_" + locale].nodes.map((item, index) => {
            return (
              <a
                href={alternateLinks && alternateLinks[index] && alternateLinks[index].href.replace("https://ikeys-realty.com", "")}
                className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                role="menuitem"
                key={index}
              >
                {item.name}
              </a>
            )}
            )}
          </div>
          <div className="border-t border-gray-100"></div>
          <div className="px-4 pt-3 pb-1">
            <p className="text-sm font-medium leading-5 text-gray-500 truncate">
              {header[locale].currency}
            </p>
          </div>
          <div className="py-1">
            {header["currencies_" + locale].nodes.map((item, index) => (
              <button
                className="block w-full px-4 py-2 text-sm leading-5 text-left text-gray-700 cursor-pointer hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                role="menuitem"
                onClick={() => {
                  dispatch({
                    type: "change_currency",
                    currency: item.code,
                  })
                  setIsDropdownOpen(false)
                }}
                key={index}
              >
                {item.name} ({item.code})
              </button>
            ))}
          </div>
        </div>
      </Transition>
    </OutsideClickHandler>
  )
}
